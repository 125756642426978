<template>
  <div v-if="account && !$route.meta.fullScreen" class="notification-bar padding padding-small layout-horizontal layout-middle">
    <el-dropdown v-if="organization" style="cursor:pointer;" class="margin-right" trigger="click">
      <div class="font-size-medium organization layout-horizontal layout-middle">
        <el-image v-if="organization.avatar" fit="cover" :src="$prepareImageUrl(organization.avatar)" class="image-logo image-small margin-right-small"></el-image>
        <span>
          {{ organization.name }}<i
            class="fas fa-caret-down margin-left-small"></i>
        </span></div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="organization in organizationList" :key="organization.id"
                          style="padding-top: 5px; padding-bottom: 5px;"
                          @click.native="handleOrganizationClick(organization)">
          <el-image class="image-logo image-small" :src="$prepareImageUrl(organization.avatar)"></el-image>
          <span class="margin-left-small font-size-medium">{{ organization.name }}</span>
        </el-dropdown-item>
        <el-dropdown-item class="padding-vertical font-size-medium" style="padding-top: 5px; padding-bottom: 5px;"
                          @click.native="handleMoreClick">
          <i class="fas fa-angle-double-right"></i>
          <span>更多组织</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dropdown trigger="click">
      <user-avatar :user="account" fit="cover"></user-avatar>
      <el-dropdown-menu slot="dropdown" class="font-size-medium">
        <el-dropdown-item @click.native="handleLogoutClick" class="font-size-medium padding-vertical">
          <i class="fas fa-door-open"></i>
          <span>退出登录</span></el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import httpapi from "../../assets/javascript/httpapi";
import OrganizationUtils from "@/assets/javascript/organization-utils";
import UserAvatar from "@/components/user/UserAvatar";

function doLoadOrganizationList() {
  this.$reqGet({
    path: '/organization/list/get/by_account',
    data: {
      pageNum: 1,
      pageSize: 5,
    }
  }).then(res => {
    this.organizationList = res.data.list;
  });
}

export default {
  name: "NotificationBar",
  components: {UserAvatar},
  mixins: [httpapi],
  data() {
    return {
      account: null,
      organization: null,
      organizationList: [],
    }
  },
  mounted() {
    this.account = this.$getAccount();
    this.organization = OrganizationUtils.$getOrganization();
    this.loadOrganizationList();
  },
  methods: {
    loadOrganizationList() {
      doLoadOrganizationList.bind(this)();
    },
    handleLogoutClick() {
      this.$logout();
      this.$router.replace('/');
    },
    handleOrganizationClick(organization) {
      this.organization = organization;
      OrganizationUtils.$setCurrentOrganization(organization);
    },
    handleMoreClick() {
      this.$router.push({name: 'organizations_choose'});
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/style.css";

.notification-bar {
  position: absolute;
  top: 0;
  right: 32px;
  box-sizing: border-box;
  text-align: right;
}

.image-avatar {
  box-shadow: 0 0 8px #bec0c0;
  cursor: pointer;
}

.organization:hover {
  opacity: 0.8;
}
</style>