<template>
  <div class="main-menu" :class="{'is-pick-up': isPickUp, 'anim__enable': isAnimationEnable, 'anim__pickup' : isPickUp, 'anim__spread' : !isPickUp}">
    <div style="height: 20vh;" class="layout-vertical layout-center">
      <ys-button @click="handlePickUpClick" class="layout-self-right">
        <i :class="['fas', isPickUp ? 'fa-chevron-circle-right' : 'fa-chevron-circle-left']"></i>
      </ys-button>
      <div class="layout-self-left layout-horizontal layout-middle font-size-medium padding-horizontal-small margin-top">
        <el-tooltip v-if="icon" :content="title" :open-delay="500">
          <div class="logo">
            <i :class="icon" style="font-size: 24px; color: #f091a6"></i>
          </div>
        </el-tooltip>
        <transition name="el-fade-in">
        <span v-if="title && !isPickUp" class="font-color-light margin-left-small font-weight-bold"
              style="white-space: nowrap">{{ title }}</span>
        </transition>
      </div>
    </div>
    <el-scrollbar class="scrollbar" style="height: 80vh">
      <slot></slot>
    </el-scrollbar>
  </div>
</template>

<script>
import YsButton from "@/components/wedigets/YsButton";

export default {
  name: "MainMenu",
  components: {YsButton},
  provide() {
    return {
      provideData: this.provideData,
    }
  },
  props: {
    currentTag: String,
    pickUp: {
      type: Boolean,
      default: true,
    },
    title: String,
    icon: String,
  },
  data() {
    return {
      isPickUp: false,
      provideData: {
        pickUp: this.pickUp,
        currentTag: this.currentTag,
      },
      isAnimationEnable: false,
    }
  },
  watch: {
    pickUp: {
      handler() {
        this.provideData.pickUp = this.pickUp;
        this.isPickUp = this.pickUp;
      },
      immediate: true,
    },
    currentTag: {
      handler() {
        this.provideData.currentTag = this.currentTag;
      },
      immediate: true,
    }
  },
  methods: {
    handlePickUpClick() {
      this.isAnimationEnable = true;
      this.isPickUp = !this.isPickUp;
      this.provideData.pickUp = this.isPickUp;
      this.$emit('update:pickUp', this.isPickUp);
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

.main-menu {
  background-color: #f091a6;
  width: 108px;
  max-width: 108px;
  height: 100vh;
  box-sizing: border-box;
  box-shadow: 0 0 16px #f091a6;
  position: relative;
  z-index: 999;
}

.main-menu.is-pick-up {
  width: 56px;
  max-width: 56px;
}

@keyframes anim-pickup {
  from {
    width: 128px;
    max-width: 128px
  }
  to {
    width: 56px;
    max-width: 56px;
  }
}

.main-menu.anim__enable.anim__pickup {
  animation-name: anim-pickup;
  animation-duration: .5s;
}

@keyframes anim-spread {
  from {
    width: 56px;
    max-width: 56px;
  }
  to {
    width: 128px;
    max-width: 128px;
  }
}

.main-menu.anim__enable.anim__spread {
  animation-name: anim-spread;
  animation-duration: .5s;
}

@keyframes anim-rotate-to {
  from {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg); /* IE 9 */
    -moz-transform: rotate(0deg); /* Firefox */
    -webkit-transform: rotate(0deg); /* Safari 和 Chrome */
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg); /* IE 9 */
    -moz-transform: rotate(180deg); /* Firefox */
    -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
    -o-transform: rotate(180deg); /* Opera */
  }
}

.scrollbar >>> .el-scrollbar__wrap {
  overflow-x: hidden;
}

.scrollbar>>>.el-scrollbar__thumb {
  background-color: transparent !important;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.menu-item.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.logo {
  width: 32px;
  height: 32px;
  background-color: #FFFFFF;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #f9d2d9;
}

</style>