<template>
  <el-tooltip :content="title" :open-delay="500" :visible-arrow="false" :disabled="!pickUp" placement="right">
    <div class="main-menu-item padding layout-horizontal font-color-light font-size-base layout-middle"
         style="padding-left: 18px;"
         :class="[{'is-pick-up': pickUp, 'is-active': provideData.currentTag === tag}]">
      <el-badge :value="newMessage" :hidden="!newMessage" type="primary">
        <i :class="icon" class="font-size-small"></i>
      </el-badge>
      <transition name="el-fade-in">
        <div class="margin-left menu-title" :class="{'font-weight-bold' : pickUp}" v-if="!pickUp">{{ title }}</div>
      </transition>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: "MainMenuItem",
  inject: {
    provideData: {
      type: Object,
    }
  },
  props: {
    icon: String,
    title: String,
    tag: String,
    newMessage: [Number, String],
  },
  data() {
    return {
      pickUp: false,
      currentTag: null,
      titleVisible: true,
      timeoutId: null,
    }
  },
  watch: {
    'provideData.pickUp': {
      handler() {
        this.pickUp = this.provideData.pickUp;
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
          this.timeoutId = null;
        }
        if (this.pickUp) {
          this.timeoutId = setTimeout(() => this.titleVisible = false, 500);
        } else {
          this.titleVisible = true;
        }
      },
      immediate: true,
    }
  },
  methods: {
    handleMenuItemClick() {
      if (this.provideData) {
        this.provideData.currentTag = this.tag;
      }
    }
  },
  mounted() {
    this.$el.addEventListener('click', this.handleMenuItemClick);
  },
  destroyed() {
    this.$el.removeEventListener('click', this.handleMenuItemClick);
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }
}
</script>

<style scoped>

.main-menu-item {
  box-sizing: border-box;
  cursor: pointer;
  height: 38px;
}

.main-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.main-menu-item.is-active {
  background-color: rgba(255, 255, 255, 0.2);
}

.main-menu-item.is-pick-up {
}

.menu-title {
  white-space: nowrap;
}


</style>