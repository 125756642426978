<template>
  <transition :name="transitionName">
    <keep-alive>
      <router-view v-bind="$attrs" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-bind="$attrs" v-if="!$route.meta.keepAlive"></router-view>
  </transition>
</template>

<script>
export default {
  name: "AnimatedRouterView",
  data() {
    return {
      transitionName: null,
    }
  },
  watch: {
    $route(to, from) {
      if (to.meta.index >= from.meta.index) {
        this.transitionName = 'slide-left';
      } else {
        this.transitionName = 'slide-right';
      }
    },
  }
}
</script>

<style scoped>

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: relative;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-0%, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  transform: translate3d(100%, 0, 0);
}


.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-10%, 0, 0);
}


</style>