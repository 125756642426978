<template>
  <div class="background">
    <div style="width: 550px; height: 90px; background-color: #98c9a9; position: absolute; right: 0px; top: 0px;"></div>
    <div style="width: 350px; height: 90px; background-color: #f091a6; position: absolute; right: 0px; top: 0px;"></div>
    <div style="width: 400px; height: 110px; background-color: #c0a8c9; position: absolute; left: 0px; bottom: 0px;"></div>
    <div style="width: 100vw; height: 120px; background-color: #ded0e3; position: absolute; left: 0px; bottom: 0px;"></div>
  </div>
</template>

<script>
export default {
  name: "Background"
}
</script>

<style scoped>


.background {
  position: absolute;
  z-index: -9999;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: white;
  filter: blur(90px) grayscale(.2);
}

</style>